<template>
	<div class="register">
		<Topq></Topq>
		<div class="content">
			<div class="content-main">
				<div class="main-title">
					<ul>
						<li :class="ulNum==1?'te':''" @click="ulClick(1)">个人账号</li>
						<li :class="ulNum==2?'te':''" @click="ulClick(2)">机构账号</li>
					</ul>
				</div>
				<div class="main-form">
					<div class="form-item" v-if="ulNum==1">
						<div class="login-main-form">
							<form>						
							<div class="login-main-form-item">
								<img src="../assets/image/phone.png">
								<el-input placeholder="请输入账号" v-model="registername" @input="e => e && (registername = e.trim())"></el-input>
							</div>
							<div class="login-main-form-item">
								<img src="../assets/image/phone.png">
								<el-input placeholder="请输入手机号" v-model="registeraccount" @input="e => e && (registeraccount = e.trim())"></el-input>
							</div>
							<div class="login-main-form-item">
								<img src="../assets/image/pas.png">							
								<el-input placeholder="请设置一个至少8位的密码"  show-password v-model="registerpassword" @input="e => e && (registerpassword = e.trim())"></el-input>
							</div>
							<div class="login-main-form-item margin">
								<img src="../assets/image/pas.png" >
								<el-input placeholder="请确认密码"  show-password v-model="registerpassword2" @input="e => e && (registerpassword2 = e.trim())"></el-input>
							</div>
							<!-- <div class="login-main-form-bottom">
								<div class="gou">
									<el-checkbox v-model="checked"><span>记住密码</span></el-checkbox>
								</div>
							</div> -->
							<div class="login-main-botton" @click="register">

								<span>注册</span>
							</div>
								</form>
						</div>
					</div>
					<div class="form-item" v-if="ulNum==2">
						<div class="login-main-form">
							<form>						
							<div class="login-main-form-item">
								<img src="../assets/image/phone.png">
								<el-input placeholder="请输入渠道名称" v-model="mechanismname"></el-input>
							</div>
							<div class="login-main-form-item">
								<img src="../assets/image/phone.png">
								<el-input placeholder="请输入手机号" v-model="mechanismaccount" @input="e => e && (mechanismaccount = e.trim())"></el-input>
							</div>
							<div class="login-main-form-item">
								<img src="../assets/image/pas.png">							
								<el-input placeholder="请设置一个至少8位的密码"  show-password v-model="mechanismpassword" @input="e => e && (mechanismpassword = e.trim())"></el-input>
							</div>
							<div class="login-main-form-item">
								<img src="../assets/image/pas.png">
								<el-input placeholder="请确认密码"  show-password v-model="mechanismpassword2" @input="e => e && (mechanismpassword2 = e.trim())"></el-input>
							</div>
							<div class="login-main-botton" @click="mechanism">
					
								<span>下一步</span>
							</div>
								</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Topq from './common/Topq.vue'
	import $http from '../common/api/axios.js'
	import {
		registerUser
	} from '../common/api/api.js'
	import md5 from 'js-md5'
	export default {
		data() {
			return {
				checked:false,
				ulNum: 1,
				registername: '',
				registeraccount: '',
				registerpassword: '',
				registerpassword2: '',
				registertiBoolean: false,
				registerpassBoolean: false,
				registerequalBoolean: false,
				mechanismname: '',
				mechanismaccount: '',
				mechanismpassword: '',
				mechanismpassword2: '',
				mechanismtiBoolean: false,
				mechanismpassBoolean: false,
				mechanismequalBoolean: false,
			}
		},
		components: {
			Topq
		},
		methods: {
			ulClick(n) {
				this.ulNum = n
			},
			register() {
				this.verification();
				if (this.registertiBoolean == false && this.registerpassBoolean == false) {
					if (this.registerpassword !== this.registerpassword2) {
						this.registerequalBoolean = true
						this.$message({
							message: '两次密码输入不同',
							type: 'warning',
							duration:'2000'
						});
					} else {
						let that = this
						this.$store.dispatch('initDic').then(() => {
							$http.request({
								url: registerUser,
								data: {
									"nickName": that.registername,
									"password": md5(that.registerpassword),
									"phone": that.registeraccount,
									"productType": 4
								}
							}).then((res) => {
								if (res.code == 200) {

									this.$message({
										message: '注册成功!',
										type: 'success',
										duration:'2000'
									});
									console.log('是否进入');
									that.$router.push('./pj-login')

								}
							}).catch(() => {

							})
						})
					}
				}
			},
			mechanism() {
				this.verifications();
				if(this.mechanismtiBoolean ==false && this.mechanismpassBoolean == false){
					if (this.mechanismpassword !== this.mechanismpassword2) {
						this.mechanismequalBoolean = true
						this.$message({
							message: '两次密码输入不同',
							type: 'warning',
							duration:'2000'
						});
					}else{
						this.$store.state.mechanismname=this.mechanismname
						this.$store.state.mechanismaccount=this.mechanismaccount
						this.$store.state.mechanismpassword=this.mechanismpassword
						this.$router.push('./pj-mechanism')
					}
				}				
			},
			//验证
			verification() {
				const regMobile = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
				if (regMobile.test(this.registeraccount)) {
					this.registertiBoolean = false
				} else {
					this.registertiBoolean = true
					this.$message({
						message: '手机号码格式不正确',
						type: 'warning',
						duration:'2000'
					});
				}
				console.log('密码',this.registerpassword);
				if (this.registerpassword.length>=8) {
					this.registerpassBoolean = false
				} else {
					this.registerpassBoolean = true
					this.$message({
						message: '密码格式不正确',
						type: 'warning',
						duration:'2000'
					});
				}
			},
			//验证
			verifications() {
				const regMobile = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
				if (regMobile.test(this.mechanismaccount)) {
					this.mechanismtiBoolean = false
				} else {
					this.mechanismtiBoolean = true
					this.$message({
						message: '手机号码格式不正确',
						type: 'warning',
						duration:'2000'
					});
				}
				const regPass = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\\W]{6,18}$/;
				if (regPass.test(this.mechanismpassword)) {
					this.mechanismpassBoolean = false
				} else {
					this.mechanismpassBoolean = true
					this.$message({
						message: '密码格式不正确',
						type: 'warning',
						duration:'2000'
					});
				}
			},
		}
	}
</script>

<style scoped>
	.content {
		display: flex;
		justify-content: center;
		padding-top: 1.48rem;
		box-sizing: border-box;
		width: 19.2rem;
		height: 10.12rem;
		background: url(../assets/image/bg4.png) no-repeat;
		background-size: 100%;
		font-family: PingFang SC;
	}

	.content-main {
		width: 9.73rem;
		height: 6.49rem;
		background: rgba(255, 255, 255, 0.85);
		box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.43);
		border-radius: 10px;
	}

	.main-title ul {
		display: flex;
		padding: 0 1.13rem;
		width: 100%;
		height: .74rem;
	}

	.main-title ul li {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 3.5rem;
		height: 100%;
		font-size: .20rem;
		font-weight: 400;
		color: #666666;

	}

	.te {
		border-bottom: 3px solid #E41D1D;
		color: #E41D1D !important;
	}

	.main-form {
		position: relative;
	}

	.login-main-form {
		position: absolute;
		left: 3rem;
		top: .62rem;
	}

	.login-main-form-item {
		margin-bottom: .2rem;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		padding-left: .16rem;
		width: 3.46rem;
		height: .5rem;
		background: #F5F5F5;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
		border-radius: 4px;
	}

	.login-main-form-item>img {
		margin-right: .1rem;
		width: .15rem;
	}

	.login-main-form-item>input {
		font-family: PingFang SC;
		font-size: .16rem;
		width: 2.7rem;
		height: .4rem;
		border: 0;
		background: #F5F5F5!important;
		outline-style: none!important;
	}
	/deep/.el-input__inner{
		border: 0;
		background: #F5F5F5!important;
		outline-style: none!important;
	}
	.login-main-form-bottom {
		margin-bottom: .64rem;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.zhuce {
		font-size: .14rem;
		font-weight: 400;
		color: #666666;
	}

	.zhuce>span {
		color: #E41D1D;
		cursor: pointer;
	}

	.gou {
		display: flex;
		align-items: center;
	}

	.gou span {
		font-size: .14rem;
		font-weight: 400;
	}

	.login-main-botton {
		margin-top: .7rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 3.46rem;
		height: .5rem;
		background: #E41D1D;
		border-radius: 4px;
		cursor: pointer;
	}

	.login-main-botton span {
		font-size: .16rem;
		font-weight: 500;
		color: #FFFFFF;
	}
	.el-input__inner {
		width: 2.56rem !important;
		height: .52rem !important;
		font-size: .14rem !important;
		font-family: PingFang SC !important;
		font-weight: 600 !important;
		color: #333333 !important;
	}
</style>
